import React, { useState, useEffect, useContext } from 'react';
// @ts-ignore
import MUIDataTable from "mui-datatables";
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { Flag } from '@material-ui/icons';
import Event from '../models/Event';
import { apiService } from '../utils/ApiService';
import { AppTitleContext } from '../context/AppTitleContext';

// Test comment to see if the web will run after publish
export default function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const [trainingEvents, setTrainingEvents] = useState([] as Event[]);
    const headerTitle = useContext(AppTitleContext);
    const [error, setError] = useState(false);

    useEffect(() => {
        loadEvents();

        // eslint-disable-next-line
    }, []);

    const loadEvents = () => {
        setIsLoading(true);

        apiService.get("noauth/ts-feedback/api/events", {
            success: (data) => {
                setTrainingEvents(data);
                setIsLoading(false);
            },
            error: () => {
                setError(true);
                setIsLoading(false)
            }
        });
    }

    const columns = [
        {
            name: "Id",
            options: {
                display: false
            }
        },
        {
            name: "SubId",
            options: {
                display: false
            }
        },
        { name: "Start", label: "Time" },
        {
            name: "Title",
            options: {
                customBodyRender: (value: string, tableMeta: any) => {
                    return <Link to={"/survey?id=" + tableMeta.rowData[0] + "&subid=" + tableMeta.rowData[1]} onClick={() => headerTitle.setAppTitle(value)}>{value}</Link>;
                }
            }
        },
        "Location",
        "Trainer",
        {
            name: "Status",
            options: {
                customBodyRender: (value: string) => {
                    if (value === "Finished")
                        return <Chip size="small" icon={<Flag />} label={value} className="bg-warning" />

                    if (value === "Closed")
                        return <Chip size="small" icon={<Flag />} label={value} color="primary" />

                    if (value === "Open")
                        return <Chip size="small" icon={<Flag />} label={value} className="bg-success" />

                    if (value === "Ongoing")
                        return <Chip size="small" icon={<Flag />} label={value} className="bg-primary" />
                }
            }
        }
    ];

    const options = {
        selectableRows: "none",
        pagination: false,
        filter: false,
        print: false,
        download: false,
        viewColumns: false,
        responsive: "simple",
        setTableProps: () => { return { size: "small" } },
        textLabels: {
            body: {
                noMatch: isLoading && <div><Skeleton animation="wave" /><Skeleton animation="wave" /></div>
            }
        }
    };

    return (
        <React.Fragment>
            <MUIDataTable
                title={"Training events"}
                data={trainingEvents}
                columns={columns}
                options={options}
                className="m-2"
            />
        </React.Fragment>
    )
}