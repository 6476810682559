import moment from 'moment';

/** Returns true if the platform is "Win32". */
export const isDesktop: boolean = navigator.platform === "Win32";

/** Returns true if the project is running localy, or is build and deployed to an address containing ".dev". */
export const isDevelopment: boolean = window.location.href.includes(".dev") || window.location.href.includes("localhost") || process.env.NODE_ENV === 'development';

export function DateFormatter(time: string) {
    return moment(time).format('DD.MM.YYYY h:mm');
}