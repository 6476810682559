import { peoplesoftUrl, apiUrl, feedbackServiceUrl } from "./BaseUrl";

interface FetchOptions {
    success: (data: any) => void,
    error: (message: string) => void
}

class ApiService {
    private _apiUrl: string = '';

    public Init(apiUrl: string) {
        this._apiUrl = apiUrl;
    }

    public async get(url: string, options: FetchOptions) {

        const initReq: RequestInit = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async post(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async put(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'put',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async delete(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'delete',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }
}

function basicFetch(url: string, options: FetchOptions, initReq: RequestInit) {
    return new Promise(async (resolve, reject) => {
        fetch(url, initReq).then(res => {
            if (res.ok) {
                res.json().then((result) => {
                    options.success(result);
                    resolve(result);
                }).catch(() => {
                    options.success(null);
                    resolve(() => { });
                })
            } else {
                options.error(res.statusText + " [" + res.status + "]");
                resolve(res.statusText + " [" + res.status + "]");
            }
        }).catch((error) => {
            options.error(error.toString());
            reject(error.toString());
        });
    });
}

//Jednotliv� (mikro)Services s p�eddefinovanou base url (pou�it�: service.apiMetoda(){} )
const apiService: ApiService = new ApiService();
const peoplesoftService: ApiService = new ApiService();
const feedbackService: ApiService = new ApiService();

//Nastav�m p��slu�n� Service base url, p�i zavol�n� dan� Service ji� nen� pot�eba d�val baseUrl ale pouze {controller}/{akce}/{parametr?}
apiService.Init(apiUrl);
peoplesoftService.Init(peoplesoftUrl);
feedbackService.Init(feedbackServiceUrl);

export { apiService, peoplesoftService, feedbackService }