import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface NotificationProps {
    open: boolean,
    handleClose: () => void,
    severity: 'success' | 'error' | 'warning' | 'info',
    children?: React.ReactNode
}

export default function Notification(props: NotificationProps) {
    return (
        <Snackbar open={props.open} onClose={props.handleClose} autoHideDuration={6000}>
            <Alert onClose={props.handleClose} severity={props.severity} variant="filled">{props.children}</Alert>
        </Snackbar>
    );
}