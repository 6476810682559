import React, { useState } from 'react';
import packageJson from '../../package.json';

export const AppTitleContext = React.createContext({
    title: packageJson.name,
    setAppTitle: (title: string) => { }
})

export function AppTitleProvider(props: { children?: React.ReactNode }) {
    const [title, setTitle] = useState("Training Feedbacks v1.0.0 ");

    return <AppTitleContext.Provider value={{ title: title, setAppTitle: setTitle }}>
        {props.children}
    </AppTitleContext.Provider>
}