import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './components/layout/Layout';

//Pages
import Home from './pages/Home';
import Survey from './pages/Survey';

//Contexts
import { AppTitleProvider } from './context/AppTitleContext';

export default () => (
    <BrowserRouter>
        <AppTitleProvider>
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/survey' component={Survey} />
                </Switch>
            </Layout>
        </AppTitleProvider>
    </BrowserRouter>
);