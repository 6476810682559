import React from 'react';
import { Link } from 'react-router-dom';
import { SwipeableDrawer, ListItem, ListItemIcon, List, Typography } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { T } from '../../utils/translate/translator';
import packageJson from '../../../package.json';

const useStyles = makeStyles({
    list: {
        width: 240
    }
});

interface LeftDrawerProps {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function LeftDrawer(props: LeftDrawerProps) {
    const classes = useStyles();

    return (
        <SwipeableDrawer anchor="left" open={props.open} onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)}>

            <div className="mx-auto">
                <Link to="/" onClick={() => props.setOpen(false)}>
                    <img src="/images/wistron.PNG" width="200" className="mt-3" alt="wistronLogo" />
                </Link>
                <p className="text-center"><b>Version: </b>{packageJson.version}</p>
            </div>

            <List className={classes.list}>
                <Link to="/" className="text-decoration-none" onClick={() => props.setOpen(false)}>
                    <ListItem button>
                        <ListItemIcon><Home fontSize="large" /></ListItemIcon>
                        <Typography color="textPrimary">{T("Home")}</Typography>
                    </ListItem>
                </Link>

            </List>

        </SwipeableDrawer>
    )
}