import { isDevelopment } from "./Helpers";

let apiUrl: string = "https://api.dev.wistron.eu/";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";
let feedbackServiceUrl: string = "http://mcs-ts-feedback.qas.wcz.wistron/api";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/";
    peoplesoftUrl = "https://api.wistron.eu/ps";
    feedbackServiceUrl = "http://mcs-ts-feedback.wcz.wistron/api";
}

//export microservices base URL
export { apiUrl, peoplesoftUrl, feedbackServiceUrl }