export const en = new Map<string, string>();

//Layout
en.set("Logout", "Logout");
en.set("Language", "Language");
en.set("DarkMode", "Dark mode");
en.set("FetchIdError", "We were unable to retrieve your Employee ID.");
en.set("DeleteCache", "Delete cache");

//Pages
en.set("Home", "Home");

// Survey form
en.set("LabelEmployeeId", "Employee Id");
en.set("PlaceholderComment", "Enter your answer");
en.set("SubmitButtonText", "Submit");
en.set("LabelAgree", "Agree");
en.set("LabelDisagree", "Disagree");