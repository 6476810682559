import React, { useState, useContext, useEffect } from 'react';
import { IconButton, Typography, Toolbar, AppBar, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { WifiOff, Home } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

//Context
import { AppTitleContext } from '../../context/AppTitleContext';

//Components
import RightDrawer from './RightDrawer';
import LeftDrawer from './LeftDrawer';

//Utils
import { isDevelopment } from '../../utils/Helpers';
import { Link } from 'react-router-dom';

const themeStoredCode: string = localStorage.getItem("theme") || 'light';
const darkTheme: Theme = createMuiTheme({ palette: { type: 'dark' } })
const lightTheme: Theme = createMuiTheme({ palette: { type: 'light' } })

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function Layout(props: { children?: React.ReactNode }) {
    const classes = useStyles();
    const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
    const [offline, setOffline] = useState(false);
    const [theme, setTheme] = useState(themeStoredCode);
    const appTitle = useContext(AppTitleContext);

    useEffect(() => {
        if (themeStoredCode === "dark")
            changeAppTheme("dark")

        window.addEventListener('online', () => setOffline(false));
        window.addEventListener('offline', () => setOffline(true));
    }, []);

    const changeAppTheme = (theme: string) => {
        document.body.style.backgroundColor = "";
        document.body.style.color = "";

        if (theme === "dark") {
            document.body.style.backgroundColor = "#212121";
            document.body.style.color = "#fafafa";
        }

        setTheme(theme);
        localStorage.setItem("theme", theme);
    }

    return (
        <MuiThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/" onClick={() => appTitle.setAppTitle("Training Feedbacks")} >
                            <IconButton>
                                <Home className="text-white"/>
                            </IconButton>
                        </Link>
                        <Typography variant="h6" className={classes.title}>{isDevelopment && "[QAS]"} {appTitle.title}</Typography>

                        {offline &&
                            <IconButton color="inherit">
                                <WifiOff />
                            </IconButton>
                        }
                    </Toolbar>
                </AppBar>
            </div>

            <LeftDrawer open={leftDrawerOpen} setOpen={setLeftDrawerOpen} />
            <RightDrawer open={rightDrawerOpen} setOpen={setRightDrawerOpen} theme={theme} changeAppTheme={changeAppTheme} />

            {props.children}
        </MuiThemeProvider>
    );
}