import React, { useState } from 'react';
import queryString from 'query-string'
import { TextField, Box, Button, OutlinedInput, InputAdornment, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Answer from '../models/Answer';
import { Row, Col } from 'reactstrap';
import { Rating } from '@material-ui/lab';
import { StarBorder, AccountCircle } from '@material-ui/icons';
import SendIcon from '@material-ui/icons/Send';
import CreateFeedbackDto from '../models/CreateFeedbackDto';
import { apiService } from '../utils/ApiService';
import Notification from '../components/common/Notification'
import { T } from '../utils/translate/translator';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1)
        },
        fontLarge: {
            fontSize: "3vh"
        }
    })
);

const testData: Answer[] = [
    {
        OrderNumber: 1,
        QuestionTextCz: "Školení bylo dobře zorganizované.",
        QuestionTextEn: "The training was well organized.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 2,
        QuestionTextCz: "Výklad byl srozumitelný.",
        QuestionTextEn: "The interpretation was understandable.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 3,
        QuestionTextCz: "Školitel/ka je na dané téma odborník.",
        QuestionTextEn: "The trainer is professional.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 4,
        QuestionTextCz: "Obsah školení pro mě byl přínosný.",
        QuestionTextEn: "The content of the training was valuable for me.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 5,
        QuestionTextCz: "Prostředí školení bylo vyhovující.",
        QuestionTextEn: "The training environment was satisfactory.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 6,
        QuestionTextCz: "Celkově pro mě bylo školení přínosné.",
        QuestionTextEn: "Overall, this course was valuable for me.",
        AnswerType: "Rating",
        Value: ""
    },
    {
        OrderNumber: 7,
        QuestionTextCz: "Zde je prostor pro další zpětnou vazbu či komentář ke školení.",
        QuestionTextEn: "Please share any other feedback or comment with us.",
        AnswerType: "Comment",
        Value: ""
    }
];

const langStoredCode: string = localStorage.getItem("langCode") ??  'cs';

export default function Survey() {
    const classes = useStyles();
    const { id, subid } = queryString.parse(window.location.search);
    const [employeeId, setEmployeeId] = useState("");
    const [answers, setAnswers] = useState(testData);
    const [showNotification, setShowNotification] = useState(false)    

    const updateAnswerValue = (orderNumber: number, value: string | number | null) => {
        let newValue = value ? value.toString() : "";

        let newArr: Answer[] = answers.map((a) => {
            return a.OrderNumber === orderNumber ? { ...a, Value: newValue } : a;
        });

        setAnswers(newArr)
    };

    const submit = () => {
        const dto: CreateFeedbackDto = {
            EventId: id ? id.toString() : "",
            EventSubId: subid ? Number(subid) : 0,
            AttendeeId: employeeId,
            AttendeeName: "Anonymous",
            Answers: answers,
            CreatedBy: "system"
        };

        apiService.post("noauth/ts-feedback/api/feedbacks", dto, {
            success: () => {
                setEmployeeId("");
                setAnswers(testData);
                setShowNotification(true);
            },
            error: () => { }
        })
    };

    return (
        <div className="m-4">
            <Row className="mb-3">
                <Col xs={10} md={10} lg={6} xl={4}>
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">{T("LabelEmployeeId")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={employeeId}
                            className={classes.fontLarge}
                            onChange={(event: any) => setEmployeeId(event.target.value)}
                            startAdornment={<InputAdornment position="start"><AccountCircle className={classes.fontLarge} /></InputAdornment>}
                            labelWidth={langStoredCode === "en" ? 95 : 120}
                        />
                    </FormControl>
                </Col>
            </Row>

            {answers.map((answer, index) => {
                return (
                    <Row>
                        <Col>
                            <Row style={{ fontSize: "2vh", fontWeight: "bold", paddingLeft: "15px" }}>
                                <span>{answer.OrderNumber}.</span>
                                <Col xs={11} md={10} lg={6}>
                                    {langStoredCode === "en" ? answer.QuestionTextEn : answer.QuestionTextCz}
                                </Col>
                            </Row>

                            {answer.AnswerType === "Rating" &&
                                <Row className="mb-4">
                                    <Col>
                                        <span className="small mr-2 d-none d-md-inline-block" style={{ verticalAlign: "top" }}>
                                            {T("LabelDisagree")}
                                        </span>
                                        <Box component="fieldset" borderColor="transparent" display="inline-block">
                                            <Rating
                                                name={"rating-" + answer.OrderNumber}
                                                value={Number(answer.Value)}
                                                precision={1}
                                                emptyIcon={<StarBorder fontSize="inherit" />}
                                                style={{ fontSize: "5vh" }}
                                                onChange={(event, newValue) => updateAnswerValue(answer.OrderNumber, newValue)}
                                            />
                                        </Box>
                                        <span className="small ml-2 d-none d-md-inline-block" style={{ verticalAlign: "top" }}>
                                            {T("LabelAgree")}
                                        </span>
                                    </Col>
                                </Row>
                            }

                            {answer.AnswerType === "Comment" &&
                                <Row className="mb-4">
                                    <Col xs={11} md={10} lg={6}>
                                        <TextField
                                            multiline
                                            rows={4}
                                            fullWidth
                                            value={answer.Value}
                                            placeholder={T("PlaceholderComment")}
                                            variant="outlined"
                                            onChange={(event: any) => updateAnswerValue(answer.OrderNumber, event.target.value)}
                                        />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                );
            })}

            <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "2.3vh" }}
                endIcon={<SendIcon />}
                onClick={() => submit()}
            >
                {T("SubmitButtonText")}
            </Button>

            <Notification open={showNotification} handleClose={() => setShowNotification(false)} severity="success">
                Děkujeme za Vaší zpětnou vazbu! :-)
            </Notification>
        </div>
    );
}