export const cs = new Map<string, string>();

//Layout
cs.set("Logout", "Odhlásit se");
cs.set("Language", "Jazyk");
cs.set("DarkMode", "Tmavý režim");
cs.set("FetchIdError", "Nepodařilo se nám získat vaše ID zaměstnance.");
cs.set("DeleteCache", "Vymazat mezipaměť");

//Stránky
cs.set("Home", "Domů");

// Survey form
cs.set("LabelEmployeeId", "Id zaměstnance");
cs.set("PlaceholderComment", "Napište Váš komentář");
cs.set("SubmitButtonText", "Odeslat");
cs.set("LabelAgree", "Souhlasím");
cs.set("LabelDisagree", "Nesouhlasím");